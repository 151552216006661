import React from 'react'

const Certifications = (props) => {
  return (
    <div id='certifications' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
        <h2>{props.data ? props.data.Threpyhdr : 'loading'}</h2>
        <h3> {props.data ? props.data.Threpypara : 'loading'}</h3>

        </div>

        <div id='row gacontain'>
          {props.data
            ? props.data.Therapyimg.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-4 col-sm-12 team'>
                <div className='thumbnail'>
                  {' '}
                  <img src={d.imgsrc} alt='...' className='team-img lazyloaded' />
                  <h3>{d.title}</h3>

                </div>
              </div>
            ))
            : 'loading'}
        </div>

        

      </div>
    </div>
  )
}

export default Certifications;
