import { useState, useEffect } from "react";
import JsonData from "../../../data/data.json";
import { Contact } from "./contact";

export const AboutDetailHi = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
      document.title = "पटना में नशा मुक्ति केंद्र @9471414102 | नशामुक्ति एवं पुनर्वास केंद्र"; 
    }, []);
    
  return (
    <div id="aboutdetail">
      <title>पटना में नशा मुक्ति केंद्र @9471414102 | नशामुक्ति एवं पुनर्वास केंद्र</title>
      <header id='header'>
        <div className='intro'>
          <div className='overlay'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-about'>


                  <h2>About Us</h2>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="policytext">
          <h3>आपका स्वागत है!</h3>
          <div>
            <h2>
            सार्थक जीवन नशा मुक्ति केंद्र में!
            </h2>
          </div>
          <div>
          <p><a href="/hnhome">सार्थक जीवन नशा मुक्ति केंद्र</a> में, हम जीवन को बदलने के लिए समर्पित हैं, जहाँ हम व्यक्तियों को नशे की जकड़ से मुक्त होने में मदद करते हैं और उन्हें उद्देश्यपूर्ण, स्वस्थ और खुशहाल जीवन फिर से पाने में सहयोग देते हैं। हमारा केंद्र उन लोगों के लिए एक आश्रय स्थल है जो पुनर्वास की तलाश में हैं, जहाँ हम सहानुभूतिपूर्ण देखभाल, पेशेवर समर्थन और सिद्ध उपचार विधियाँ प्रदान करते हैं।</p>
          </div>
          <div>
            <h3>हम क्यों चुनें?</h3>
            <ol>
            <li><p><b><strong>1. विशेषज्ञ टीम:</strong></b> हमारे स्टाफ में अनुभवी डॉक्टर, थेरेपिस्ट और काउंसलर शामिल हैं, जो नशामुक्ति उपचार में विशेषज्ञता रखते हैं।</p></li>
            <li><p><b><strong>2. समग्र दृष्टिकोण:</strong></b> हम चिकित्सा उपचार, काउंसलिंग, योग, ध्यान और व्यक्तिगत थेरेपी को मिलाकर शरीर, मन और आत्मा को स्वस्थ करते हैं।</p></li>
            <li><p><b><strong>3. व्यक्तिगत देखभाल:</strong></b> हर व्यक्ति की रिकवरी यात्रा अनोखी होती है। हम प्रत्येक मरीज की विशेष आवश्यकताओं के अनुसार उपचार योजना तैयार करते हैं।</p></li>
            <li><p><b><strong>4. सुरक्षित और सहायक वातावरण:</strong></b> हम एक शांतिपूर्ण और बिना किसी भेदभाव वाला माहौल प्रदान करते हैं, जहाँ व्यक्ति पूरी तरह से अपने उपचार पर ध्यान केंद्रित कर सकते हैं।</p></li>
            <li><p><b><strong>5. उपचार के बाद सहयोग:</strong></b> रिकवरी केवल उपचार तक सीमित नहीं होती। हम लगातार फॉलो-अप और सहायता प्रदान करते हैं ताकि दोबारा नशे की लत से बचा जा सके।</p></li>            
            </ol>
          </div>

          <div>
          <h3>हमारे कार्यक्रम</h3>
          <ol>
            <li><p><b><strong>• डिटॉक्सिफिकेशन:</strong></b> शरीर से हानिकारक पदार्थों को निकालने के लिए सुरक्षित और चिकित्सकीय रूप से निगरानी किया गया डिटॉक्स।</p></li>
            <li><p><b><strong>• काउंसलिंग और थेरेपी:</strong></b> नशे से जुड़े भावनात्मक, मानसिक और सामाजिक पहलुओं को समझने के लिए व्यक्तिगत और समूहिक थेरेपी सत्र।</p></li>
            <li><p><b><strong>• पुनर्वास (Rehabilitation):</strong></b> 24/7 देखभाल और समर्थन के साथ व्यापक आवासीय पुनर्वास कार्यक्रम।</p></li>
            <li><p><b><strong>• समग्र उपचार (Holistic Healing):</strong></b> संपूर्ण स्वास्थ्य को बढ़ावा देने के लिए योग, ध्यान और मनोरंजक गतिविधियों का समावेश।</p></li>
          </ol>
          
          </div>
          <div>
          <h3>हमारा वादा</h3>
          <p><a href="/">सार्थक जीवन नशा मुक्ति केंद्र</a> में, हमारा विश्वास है कि हर व्यक्ति एक दूसरा मौका पाने का हकदार है। अपने समर्पण, विशेषज्ञता और करुणा के साथ, हम आपके साथ हर कदम पर चलते हैं, ताकि आप एक उज्जवल और नशामुक्त भविष्य की ओर बढ़ सकें।</p>
          </div>
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};
