import { useState, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import JsonData from "./data/data.json";


import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import Home from "./Home";
import { Errorpage } from "./components/pages/errorpage";
import { Thanks } from "./components/pages/thanks";
import { Footer } from "./components/footer";
import Footerdetail from "./components/Footerdetail";
import { Returnypolicy } from "./components/pages/returnpolicy";
import { TermsCondition } from "./components/pages/termscondition";
import { Faqp } from "./components/pages/faqp";
import { Privacypolicy } from "./components/pages/privacypolicy";
import { Helpcenter } from "./components/pages/helpcenter";
import { AboutDetail } from "./components/pages/aboutdetail";
import { PrivacyPolicies2 } from "./components/privacypolicy2";
import { Alcohol } from "./components/pages/issueaddiction/alcohol";
import { Drug } from "./components/pages/issueaddiction/drugs"
import { Cocaine } from "./components/pages/issueaddiction/cocaine";
import { Heroin } from "./components/pages/issueaddiction/heroin";
import { Marijuana } from "./components/pages/issueaddiction/marijuana";
import { Whitener } from "./components/pages/issueaddiction/whitener";
import { BrownSugar } from "./components/pages/issueaddiction/brownsugar";
import { Injection } from "./components/pages/issueaddiction/injection";
import { Tablets } from "./components/pages/issueaddiction/tablets";
import HnHome from "./components/language/HindiHome";
import { AboutDetailHi } from "./components/language/hindi/aboutdetailhi";
import { AlcoholHin } from "./components/language/hindi/issueaddiction/alcohol";
import { CocaineHin } from "./components/language/hindi/issueaddiction/cocaine";
import { DrugHin } from "./components/language/hindi/issueaddiction/drugs";
import { HeroinHin } from "./components/language/hindi/issueaddiction/heroin";
import { MarijuanaHin } from "./components/language/hindi/issueaddiction/marijuana";
import { WhitenerHin } from "./components/language/hindi/issueaddiction/whitener";
import { BrownSugarHin } from "./components/language/hindi/issueaddiction/brownsugar";
import { InjectionHin } from "./components/language/hindi/issueaddiction/injection";
import { TabletsHin } from "./components/language/hindi/issueaddiction/tablets";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {


  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      
        <div className='social'>
        <div>
          <a className="whatsapp" href="https://api.whatsapp.com/send?phone=919044605877" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a className="phone" href="tel:9044605877">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </div>
        
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/aboutus-detail" element={<AboutDetail data={landingPageData.Uddes} />} />
        <Route path="/alcohol-addiction-treatment-patna" element={<Alcohol />}/>
        <Route path="/cocaine-addiction-treatment-patna" element={<Cocaine />}/>
        <Route path="/drug-addiction-treatment-patna" element={<Drug />}/>
        <Route path="/heroin-addiction-treatment-patna" element={<Heroin />}/>
        <Route path="/marijuana-addiction-treatment-patna" element={<Marijuana />}/>
        <Route path="/whitener-addiction-treatment-patna" element={<Whitener />}/>
        <Route path="/brownsugar-addiction-treatment-patna" element={<BrownSugar />}/>
        <Route path="/injection-addiction-treatment-patna" element={<Injection />}/>
        <Route path="/tablet-addiction-treatment-patna" element={<Tablets />}/>


        <Route path="/alcohol-addiction-treatment-patna-hin" element={<AlcoholHin />}/>
        <Route path="/cocaine-addiction-treatment-patna-hin" element={<CocaineHin />}/>
        <Route path="/drug-addiction-treatment-patna-hin" element={<DrugHin />}/>
        <Route path="/heroin-addiction-treatment-patna-hin" element={<HeroinHin />}/>
        <Route path="/marijuana-addiction-treatment-patna-hin" element={<MarijuanaHin />}/>
        <Route path="/whitener-addiction-treatment-patna-hin" element={<WhitenerHin />}/>
        <Route path="/brownsugar-addiction-treatment-patna-hin" element={<BrownSugarHin />}/>
        <Route path="/injection-addiction-treatment-patna-hin" element={<InjectionHin />}/>
        <Route path="/tablet-addiction-treatment-patna-hin" element={<TabletsHin />}/>

        <Route path="/hnhome" element={<HnHome />}/>
        <Route path="/aboutus-detail-hn" element={<AboutDetailHi />}/>
      </Routes>
   
      {/*
        
      <Route exact path="*" element={<Errorpage />} />
        <Route exact path="/thanks" element={<Thanks />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/return-policy" element={<Returnypolicy />} />
        <Route path="/help-center" element={<Helpcenter />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/faq" element={<Faqp />} />
        <Footerdetail data={landingPageData.Contact} />
        */}
     
      
      <Footer data={landingPageData.Footerdetail} />
    </div>

  );
};


export default App;

