import emailjs from 'emailjs-com'
import React, { useState, useRef } from 'react';

export const FaqCommon = (props) => {
    return (
        <div className="tz-2-com tz-2-main tz4">
            <h4> Frequently Asked Questions </h4>
            <div className="col-md-12 main4">
                <div className="demo">
                    <div>

                        <div className="panel-group" id="accordion">

                            <div className="card panel panel-default">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> पटना में सार्थक जीवन नशा मुक्ति केंद्र के बारे में बताइए?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div className="panel-body" >
                                        <a href="/hnhome">सार्थक जीवन नशा मुक्ति केंद्र</a>, पटना एक विशेष पुनर्वास केंद्र है, जो व्यक्तियों को नशे, विशेष रूप से मादक पदार्थों और शराब की लत से छुटकारा पाने में मदद करने के लिए समर्पित है। यह केंद्र परामर्श, थेरेपी और समर्थन सहित व्यापक उपचार कार्यक्रम प्रदान करता है, ताकि लोग एक स्वस्थ और नशा मुक्त जीवन जी सकें।
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> सार्थक जीवन नशा मुक्ति केंद्र की सेवाओं का लाभ कौन उठा सकता है?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div className="panel-body">
                                        <a href="/hnhome">सार्थक जीवन नशा मुक्ति केंद्र</a> उन सभी लोगों के लिए बनाया गया है जो शराब, नशीले पदार्थों और अन्य हानिकारक पदार्थों की लत से जूझ रहे हैं। चाहे आप अपने लिए मदद चाहते हों या किसी प्रियजन के लिए, हमारा केंद्र उन लोगों को व्यक्तिगत देखभाल प्रदान करता है जो नशे की लत को छोड़ने के लिए प्रतिबद्ध हैं।
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingThree">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i>  सार्थक जीवन नशा मुक्ति केंद्र में कौन-कौन से उपचार विकल्प उपलब्ध हैं?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div className="panel-body">
                                        <a href="/hnhome">सार्थक जीवन नशा मुक्ति केंद्र</a> विभिन्न उपचार विकल्प प्रदान करता है, जिनमें शामिल हैं:
                                        <ul>

                                            <li><b>. डिटॉक्सिफिकेशन प्रोग्राम:</b> चिकित्सा देखरेख में सुरक्षित रूप से नशे से मुक्ति।</li>
                                            <li><b>. व्यक्तिगत परामर्श:</b> नशे के पीछे के मानसिक कारणों को समझने और हल करने के लिए एक-से-एक थेरेपी।</li>
                                            <li><b>. समूह थेरेपी:</b> सहकर्मी समर्थन समूह, जहां अनुभव साझा किए जाते हैं और आपसी जुड़ाव बढ़ता है।</li>
                                            <li><b>. पारिवारिक परामर्श:</b> परिवार के सदस्यों को जागरूक करने और पुनर्वास प्रक्रिया में शामिल करने के लिए कार्यक्रम।</li>
                                            <li><b>. पुनर्प्राप्ति के बाद समर्थन:</b> दोबारा लत लगने से बचाने और दीर्घकालिक सफलता सुनिश्चित करने के लिए फॉलो-अप प्रोग्राम।</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> उपचार कार्यक्रम कितने समय तक चलता है?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseFour" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                    <div className="panel-body">
                                        उपचार कार्यक्रम की अवधि व्यक्ति की विशिष्ट आवश्यकताओं और प्रगति पर निर्भर करती है। आमतौर पर, विषहरण चरण 7-10 दिनों तक चलता है, उसके बाद पुनर्वास होता है, जो 1 से 6 महीने तक चल सकता है। आफ्टरकेयर कार्यक्रम लंबे समय तक रिकवरी का समर्थन करते रहते हैं।
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingFive">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i>क्या उपचार कार्यक्रम गोपनीय हैं?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                    <div className="panel-body">
                                        हाँ, <a href='/'>सार्थक जीवन नशा मुक्ति केंद्र</a> में हम मरीज की गोपनीयता को प्राथमिकता देते हैं। सभी उपचार, परामर्श और व्यक्तिगत जानकारी को पूरी तरह गोपनीय रखा जाता है। हम सख्त नैतिक दिशानिर्देशों का पालन करते हैं ताकि आपकी पुनर्वास यात्रा सुरक्षित और सम्मानजनक बनी रहे।
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingSix">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> क्या नासा मुक्ति केंद्र किसी अस्पताल या स्वास्थ्य संस्थान से संबद्ध है?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                    <div className="panel-body">
                                        हाँ, <a href='/'>सार्थक जीवन नशा मुक्ति केंद्र</a> स्वास्थ्य विशेषज्ञों और अस्पतालों के साथ मिलकर काम करता है ताकि हमारे मरीजों को सर्वोत्तम देखभाल मिल सके। हम अनुभवी डॉक्टरों, मनोचिकित्सकों और नशा विशेषज्ञों के साथ सहयोग करते हैं ताकि एक समग्र उपचार योजना प्रदान की जा सके।
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingSeven">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> मैं सार्थक जीवन नासा मुक्ति केंद्र में कैसे भर्ती हो सकता हूं?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                    <div className="panel-body">
                                        <a href='/'>सार्थक जीवन नशा मुक्ति केंद्र</a> में प्रवेश लेने के लिए आप हमें फोन के माध्यम से या हमारी वेबसाइट के जरिए संपर्क कर सकते हैं। आप हमारे केंद्र, पटना में आकर उपलब्ध उपचार विकल्पों पर चर्चा भी कर सकते हैं। हमारी टीम आपको प्रवेश प्रक्रिया में मार्गदर्शन देगी और व्यक्ति की जरूरतों के अनुसार एक उपयुक्त उपचार योजना तैयार करेगी।
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingEight">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> सार्थक जीवन नशा मुक्ति केंद्र में उपचार की लागत कितनी है?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                    <div className="panel-body">
                                        उपचार की लागत देखभाल के प्रकार और कार्यक्रम की अवधि के अनुसार भिन्न होती है। हम किफायती उपचार योजनाएँ प्रदान करते हैं और लचीले भुगतान विकल्प उपलब्ध कराते हैं। अधिक जानकारी के लिए, कृपया हमसे सीधे संपर्क करें ताकि आपकी विशिष्ट आवश्यकताओं के अनुसार एक व्यक्तिगत मूल्य विवरण प्राप्त किया जा सके।
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingNine">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i>  परिवार के सदस्य इलाज करवा रहे अपने प्रियजन का समर्थन कैसे कर सकते हैं?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                                    <div className="panel-body">
                                        परिवार की भागीदारी पुनर्वास प्रक्रिया में महत्वपूर्ण भूमिका निभाती है। हम परिवार परामर्श सत्र प्रदान करते हैं ताकि परिजन नशे की समस्या को समझ सकें और भावनात्मक समर्थन दे सकें। परिवार के सदस्यों को नशे और पुनर्वास के बारे में शिक्षित करने से रोगी के लिए एक सहयोगी वातावरण बनता है, जिससे सफल पुनर्प्राप्ति की संभावना बढ़ जाती है।
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingTen">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> यदि मैं या मेरा प्रियजन उपचार के बाद फिर से नशे की लत में पड़ जाता है तो क्या होगा?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordion">
                                    <div className="panel-body">
                                        रिलैप्स पुनर्प्राप्ति यात्रा का एक हिस्सा हो सकता है, लेकिन इसका मतलब असफलता नहीं है। <a href='/'>सार्थक जीवन नशा मुक्ति केंद्र</a> में, हम निरंतर सहायता और परामर्श प्रदान करते हैं ताकि व्यक्ति दोबारा सही राह पर लौट सके। हमारे पास एक मजबूत आफ्टरकेयर प्रोग्राम और सहायता समूह हैं, जो पुनः नशे की लत से बचने और दीर्घकालिक पुनर्वास को प्रोत्साहित करने में मदद करते हैं।
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingEleven">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> क्या मैं उपचार पर निर्णय लेने से पहले परामर्श के लिए सार्थक जीवन नासा मुक्ति केंद्र जा सकता हूं?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordion">
                                    <div className="panel-body">
                                        हाँ, आप <a href='/'>सार्थक जीवन नशा मुक्ति केंद्र</a> परामर्श के लिए शेड्यूल कर सकते हैं, जहां आप हमारे चिकित्सा और परामर्श टीम से अपनी स्थिति पर चर्चा कर सकते हैं। हम आपके सभी प्रश्नों के उत्तर देने और आपको सही उपचार प्रक्रिया के बारे में मार्गदर्शन करने के लिए तैयार हैं, ताकि आप एक सूचित निर्णय ले सकें।
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingTwelve">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="true" aria-controls="collapseTwelve">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> अधिक जानकारी के लिए मैं नासा मुक्ति केंद्र से कैसे संपर्क करूं?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" data-parent="#accordion">
                                    <div className="panel-body">
                                        आप हमसे फ़ोन पर संपर्क कर सकते हैं <a href="tel:9044605877">+91-9044605877</a>  या हमारे केंद्र पर जाएँ <a href='https://maps.app.goo.gl/awhtbY6fTRawUqZf8' target='blank'>इट्टा फैक्ट्री, संपतचक, थाना - गोपालपुरा, बैरिया, पटना- 800007</a> पटना में। अधिक जानकारी के लिए, हमारी वेबसाइट के संपर्क फ़ॉर्म के माध्यम से बेझिझक संपर्क करें, और हम तुरंत आपसे संपर्क करेंगे।
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}