export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 aboutimage">
            {" "}
            <img src="img/about.jpeg" className="img-responsive" alt="Aboutus" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Best Nasha Mukti Kendra in Patna</h2>
              <ol>
              {props.data
                ? props.data.paragraph.map((d, i) => (
                    <p key={`${d}-${i}`}>{d}</p>
                  ))
                : "loading"}
              </ol>

              
            </div>
          </div>
          <div>
          <p className="about-text">{props.data ? props.data.paradown : 'loading'}</p>
          <a
            href={props.data ? props.data.url : 'loading'}
            className='btn btn-customcolor btn-lg page-scroll'
          >
            KNOW MORE
          </a>{' '}
        </div>
        </div>
      </div>
    </div>
  );
};
