
import emailjs from 'emailjs-com'
import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";

const initialState = {
  from_name: '',
  email: '',
  mobile: '',
  adress: '',
}
export const Contact = (props) => {
  const [{ from_name,email, mobile,adress }, setState] = useState(initialState);
  const formRef = useRef();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const validateMobile = (mobile) => {
    const regex = /^\d{10}$/; // Regex for 10 digit mobile number
    return regex.test(mobile);
  };

  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateMobile(mobile)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }
    console.log(from_name,email, mobile,adress)
    emailjs
      .sendForm(
        'service_jhvavwm', 'template_37fc66s', e.target, 'ApOnybWaQRwdj7Zxt'
      )
      .then(
        (result) => {
          alert('Your Call Back Request has been submitted , you will get a get from our team within 5-10 min. Thanks for your patience')
          clearState();
          formRef.current.reset();
          navigate("/");
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='section-title'>
              <h2>कॉल बैक अनुरोध सबमिट करें</h2>
              <p>
              कृपया नीचे दिए गए फॉर्म को भरकर हमें ईमेल भेजें, और हम जल्द से जल्द आपसे संपर्क करेंगे।
              </p>
              </div>
              <form ref={formRef} name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-10'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='from_name'
                        className='form-control'
                        placeholder='Full Name*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                      <input
                      type='email'
                      id='email'
                      name='email'
                      className='form-control'
                      placeholder='Email'
                      onChange={handleChange}
                    />
                    <p className='help-block text-danger'></p>
                      <input
                        type='number'
                        id='mobile'
                        name='mobile'
                        className='form-control'
                        placeholder='Mobile Number*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                      <div className='form-group'>
                        <textarea
                          name='adress'
                          id='adress'
                          className='form-control'
                          rows='3'
                          placeholder='Address*'
                          onChange={handleChange}
                        >
                        </textarea>
                        <p className='help-block text-danger'></p>
                      </div>
                      <div id='success'></div>

                    </div>
                  </div>


                </div>

                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                कॉल बैक प्राप्त करें
                </button>
              </form>
            </div>
          </div>

          <div className='col-md-5 col-md-offset-1 contact-info'>
          
          <div className="contact-cards">
            <div className="contact-card">
              <div className="contact-icon">📞</div>
              <h2>हमसे बात करें</h2>
              <p>हम आपकी सहायता के लिए यहां हैं। हम आपकी कैसे मदद कर सकते हैं? बस हमें कॉल करें, और हम आपका मार्गदर्शन करेंगे।</p>
              <a href="tel:9044605877" className="contact-link">+91 9044 60 5877</a>
            </div>
            <div className="contact-card">
              <div className="contact-icon">💬</div>
              <h2>मेल ग्राहक सहायता</h2>
                <p>कभी-कभी आपको अपने दोस्तों या एक सहायता प्रतिनिधि की जरूरत होती है। चिंता न करें... हम आपकी मदद के लिए हमेशा यहां हैं।</p>
                <a href="mailto:sarthakjeevannasamuktikendra@gmail.com" className="contact-link">Contact Support</a>
            </div>
          </div>
          </div>
          
        </div>
      </div>

    </div>
  )
}
