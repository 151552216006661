import React, { useEffect, useState } from 'react'

export const Navigation = (props) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'  >{/**/}
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
            onClick={handleMenuClick}
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='/'>
            <img style={{ height: 60, overflow: 'hidden' }} align="left" src="img/sarlogo.png" />
          </a>{' '}
        </div>

        <div
          className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='/' className='page-scroll' onClick={handleLinkClick}>
                Home
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll' onClick={handleLinkClick}>
                About Us
              </a>
            </li>


            <li>
              <a href='#team' className='page-scroll' onClick={handleLinkClick}>
                Gallrey
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll' onClick={handleLinkClick}>
                Services
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll' onClick={handleLinkClick}>
                Contact Us
              </a>
            </li>

            {/*<li>
              <a href='#features' className='page-scroll'>
                What We Do
              </a>
            </li> 
            
            
            <li>
              <a href='#services' className='page-scroll' onClick={handleLinkClick}>
                Services
              </a>
            </li>
            <li>
              <a href='#testimonials' className='page-scroll' onClick={handleLinkClick}>
                Testimonials
              </a>
            </li>
            */}

            <li>

              <a className="btn-right" href="tel:9044605877" style={{ color: '#ef630b' }}>Call Us: 9044605877</a>
            </li>
            <li>
              <a href='/hnhome' className='page-scroll' onClick={handleLinkClick}>
                हिन्दी
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
